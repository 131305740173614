export const p1 = document.getElementById('typeEffectP1'),
    p2 = document.getElementById('typeEffectP2'),
    p3 = document.getElementById('typeEffectP3'),
    s1 = document.getElementById('typeEffectS1'),
    p4 = document.getElementById('typeEffectP4'),
    s2 = document.getElementById('typeEffectS2'),
    p5 = document.getElementById('typeEffectP5');


export const ip1 = document.querySelector('.ip1'),
    ip2 = document.querySelector('.ip2'),
    ip3 = document.querySelector('.ip3'),
    introParagraphs = [ip1, ip2, ip3];

export const introAutotypeMessages = [
    `Welcome to OBOTEO`,
    `We are a boutique Artificial Intelligence company. We provide AI App Development, Strategic AI Advisory, and Data Consultancy services, all designed to optimise your business`,
    `Find out more. Meet TEO, our business development bot`
];
export const tutotialText = [
    `Our TEO program is powered by Artificial Intelligence`,
    `That means that he learns from each conversation that he has. What he doesn't know today,
     he'll learn by tomorrow.`,
    `He's waiting to tell you all about us. All you need to do to get him started is 
    now type the word `, `"Hi" `, `or`, `"Hello"`, `in the box below`
];
export const footerMessages = [
    `OBOTEO’s&nbsp;mission is&nbsp;to&nbsp;power-up your&nbsp;business with your own proprietary&nbsp;A.I. technology, designed&nbsp;specifically to&nbsp;meet your&nbsp;business&nbsp;needs`,
    `"The ability to learn faster than your competitors
        may be the only sustainable competitive advantage" - Arie de Geus.`
];
export const contactContent = [
    `<a class="tel link" href="tel:+97144313439">
        <div class="icon-bg">
            <img src="images/tel.png" alt="Telephone number">
        </div>
        +97144313439
    </a> 
    <a class="mail link" href="mailto:teo@oboteo.com">
        <div class="icon-bg">
            <img src="images/mail.png" alt="E-mail address">
        </div>
        teo@oboteo.com
    </a>
    <div class="socials">

        <a class="linkedin " target="_blank" href="https://www.linkedin.com/in/teo-at-oboteo-702355152/">
            <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                 viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
            <path style="fill:#FFFFFF;" d="M119.18,164.715H16.396c-4.562,0-8.258,3.698-8.258,8.258v330.204c0,4.562,3.696,8.258,8.258,8.258
                H119.18c4.562,0,8.258-3.696,8.258-8.258V172.973C127.438,168.413,123.742,164.715,119.18,164.715z"/>
            <path style="fill:#FFFFFF;" d="M67.825,0.563C30.426,0.563,0,30.956,0,68.314c0,37.374,30.426,67.779,67.825,67.779
                c37.369,0,67.771-30.406,67.771-67.779C135.597,30.956,105.195,0.563,67.825,0.563z"/>
            <path style="fill:#FFFFFF;" d="M380.621,156.508c-41.282,0-71.799,17.747-90.309,37.911v-21.446c0-4.56-3.696-8.258-8.258-8.258
                H183.62c-4.562,0-8.258,3.698-8.258,8.258v330.204c0,4.562,3.696,8.258,8.258,8.258h102.56c4.562,0,8.258-3.696,8.258-8.258
                V339.803c0-55.053,14.954-76.501,53.331-76.501c41.796,0,45.117,34.383,45.117,79.334v160.543c0,4.562,3.696,8.258,8.258,8.258
                h102.598c4.562,0,8.258-3.696,8.258-8.258V322.056C512,240.194,496.391,156.508,380.621,156.508z"/>
            </svg>
        </a>

        <a class="instagram" target="_blank" href="https://www.instagram.com/oboteo.ai/">
            <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	             viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
                <path style="fill:#FFFFFF;" d="M65.741,0.021H446.29c36.154,0,65.71,29.566,65.71,65.72V446.29c0,36.144-29.556,65.69-65.71,65.69
                    H65.741C29.587,511.98,0,482.432,0,446.29V65.741C0,29.587,29.587,0.021,65.741,0.021z M372.985,56.882
                    c-12.694,0-23.049,10.395-23.049,23.083v55.149c0,12.692,10.354,23.064,23.049,23.064h57.861c12.674,0,23.05-10.371,23.05-23.064
                    V79.964c0-12.687-10.377-23.083-23.05-23.083C430.846,56.882,372.985,56.882,372.985,56.882z M454.117,216.521h-45.049
                    c4.272,13.952,6.588,28.665,6.588,43.962c0,85.132-71.281,154.18-159.174,154.18c-87.871,0-159.154-69.048-159.154-154.18
                    c0-15.297,2.338-30.01,6.589-43.962h-47v216.289c0,11.201,9.146,20.313,20.337,20.313h356.535c11.201,0,20.328-9.112,20.328-20.313
                    V216.521z M256.483,155.269c-56.764,0-102.81,44.592-102.81,99.649c0,55.001,46.046,99.627,102.81,99.627
                    c56.794,0,102.842-44.626,102.842-99.627C359.325,199.859,313.277,155.269,256.483,155.269z"/>
           </svg>
        </a>



        <a class="facebook"  target="_blank" href="https://www.facebook.com/OBOTEOchat/">
            <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	             viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
            <path style="fill:#FFFFFF;" d="M134.941,272.691h56.123v231.051c0,4.562,3.696,8.258,8.258,8.258h95.159
                c4.562,0,8.258-3.696,8.258-8.258V273.78h64.519c4.195,0,7.725-3.148,8.204-7.315l9.799-85.061c0.269-2.34-0.472-4.684-2.038-6.44
                c-1.567-1.757-3.81-2.763-6.164-2.763h-74.316V118.88c0-16.073,8.654-24.224,25.726-24.224c2.433,0,48.59,0,48.59,0
                c4.562,0,8.258-3.698,8.258-8.258V8.319c0-4.562-3.696-8.258-8.258-8.258h-66.965C309.622,0.038,308.573,0,307.027,0
                c-11.619,0-52.006,2.281-83.909,31.63c-35.348,32.524-30.434,71.465-29.26,78.217v62.352h-58.918c-4.562,0-8.258,3.696-8.258,8.258
                v83.975C126.683,268.993,130.379,272.691,134.941,272.691z"/>
            </svg>
        </a>


        <a class="twitter " target="_blank" href="https://twitter.com/OBOTEO1">
            <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	            viewBox="0 0 410.155 410.155" style="enable-background:new 0 0 410.155 410.155;" xml:space="preserve">
                <path style="fill:#FFFFFF;" d="M403.632,74.18c-9.113,4.041-18.573,7.229-28.28,9.537c10.696-10.164,18.738-22.877,23.275-37.067
                    l0,0c1.295-4.051-3.105-7.554-6.763-5.385l0,0c-13.504,8.01-28.05,14.019-43.235,17.862c-0.881,0.223-1.79,0.336-2.702,0.336
                    c-2.766,0-5.455-1.027-7.57-2.891c-16.156-14.239-36.935-22.081-58.508-22.081c-9.335,0-18.76,1.455-28.014,4.325
                    c-28.672,8.893-50.795,32.544-57.736,61.724c-2.604,10.945-3.309,21.9-2.097,32.56c0.139,1.225-0.44,2.08-0.797,2.481
                    c-0.627,0.703-1.516,1.106-2.439,1.106c-0.103,0-0.209-0.005-0.314-0.015c-62.762-5.831-119.358-36.068-159.363-85.14l0,0
                    c-2.04-2.503-5.952-2.196-7.578,0.593l0,0C13.677,65.565,9.537,80.937,9.537,96.579c0,23.972,9.631,46.563,26.36,63.032
                    c-7.035-1.668-13.844-4.295-20.169-7.808l0,0c-3.06-1.7-6.825,0.485-6.868,3.985l0,0c-0.438,35.612,20.412,67.3,51.646,81.569
                    c-0.629,0.015-1.258,0.022-1.888,0.022c-4.951,0-9.964-0.478-14.898-1.421l0,0c-3.446-0.658-6.341,2.611-5.271,5.952l0,0
                    c10.138,31.651,37.39,54.981,70.002,60.278c-27.066,18.169-58.585,27.753-91.39,27.753l-10.227-0.006
                    c-3.151,0-5.816,2.054-6.619,5.106c-0.791,3.006,0.666,6.177,3.353,7.74c36.966,21.513,79.131,32.883,121.955,32.883
                    c37.485,0,72.549-7.439,104.219-22.109c29.033-13.449,54.689-32.674,76.255-57.141c20.09-22.792,35.8-49.103,46.692-78.201
                    c10.383-27.737,15.871-57.333,15.871-85.589v-1.346c-0.001-4.537,2.051-8.806,5.631-11.712c13.585-11.03,25.415-24.014,35.16-38.591
                    l0,0C411.924,77.126,407.866,72.302,403.632,74.18L403.632,74.18z"/>
            </svg>
        </a>
    </div>
    `
];
export const msgDuration = 3000,
    isMobile = document.body.clientWidth <= 500,
    footerCloseButton = document.querySelector('.footer-close'),
    footer = document.querySelector('.footer'),
    footerText = document.querySelector('.footer-text'),

    contactDetailsMobile = document.querySelector('.sticky-contacts-container'),
    contactContainerMobile = document.querySelector('.contacts-container'),
    contactsMobileButton = document.querySelector('.contacts-half-circle'),

    introMsg = document.querySelector('.intro-msg'),
    robot = document.querySelector('.robot'),
    laptop = document.querySelector('.laptop'),
    triangles = [document.querySelector('.right-top-overlay'), document.querySelector('.right-bottom-overlay')],
    header = document.querySelector('.header'),

    introVideo = document.querySelector('.intro-video'),
    laptopText = document.querySelector('.introduction-text__wrapper'),
    myapp = document.querySelector('.app'),
    mainVideo = document.querySelector('.second-video'),
    startBtn = document.querySelector('.btn');

export const isMobileDevice = {
    Android: function () {
        return navigator.userAgent.match(/Android/i);
    },
    BlackBerry: function () {
        return navigator.userAgent.match(/BlackBerry/i);
    },
    iOS: function () {
        return navigator.userAgent.match(/iPhone|iPad|iPod/i);
    },
    Opera: function () {
        return navigator.userAgent.match(/Opera Mini/i);
    },
    Windows: function () {
        return navigator.userAgent.match(/IEMobile/i) || navigator.userAgent.match(/WPDesktop/i);
    },
    any: function () {
        return (isMobileDevice.Android() || isMobileDevice.BlackBerry() || isMobileDevice.iOS() || isMobileDevice.Opera() || isMobileDevice.Windows());
    }
};