import * as cts from './constants.js'
let showTutorial = true,
    started = false,
    wantTypeEffect = true,
    footerInterval = true,
    success = false;
const sleep = ms => new Promise(res => setTimeout(res, ms));
const fadeout = el => el.classList.add('fadeout');
const fadein = el => el.classList.remove('fadeout');
if(/chrome/.test( navigator.userAgent.toLowerCase())){
    cts.introVideo.style.filter = "contrast(101%)";
}
window.onscroll = () => {
    if(!window.innerHeight > window.innerWidth){
        alert("Please use Landscape!");
        window.scrollTo(0,0);
    }

};

if(cts.isMobileDevice.any() !== undefined && cts.isMobileDevice.any() !== null){
    document.body.classList.add('immobile');
}
cts.startBtn.onclick = async () => {
    if(cts.isMobileDevice.any()) {
        try {
            //toggleFullScreen();
        }
        catch (e) {
            // console.log(e)
        }
    }
   await sleep(300);
   fadeout(cts.introMsg);
   fadein(cts.footer);
   cts.contactContainerMobile.innerHTML = cts.contactContent;
   await sleep(300);
   await startTalking();

};
cts.footerCloseButton.onclick = () => {
    cts.footer.classList.add('fadeout');
    setTimeout(()=>{
        cts.footer.remove();
        footerInterval = false;
    }, 1000);

};
function toggleFullScreen() {
    var doc = window.document;
    var docEl = doc.documentElement;

    var requestFullScreen = docEl.requestFullscreen || docEl.mozRequestFullScreen || docEl.webkitRequestFullScreen || docEl.msRequestFullscreen;
    var cancelFullScreen = doc.exitFullscreen || doc.mozCancelFullScreen || doc.webkitExitFullscreen || doc.msExitFullscreen;

    if(!doc.fullscreenElement && !doc.mozFullScreenElement && !doc.webkitFullscreenElement && !doc.msFullscreenElement) {
        requestFullScreen.call(docEl);
    }
    else {
        cancelFullScreen.call(doc);
    }
}

cts.contactsMobileButton.onclick = () => {
    cts.contactsMobileButton.parentNode.classList.contains('collapsed_stick') ?
        cts.contactsMobileButton.parentNode.classList.remove('collapsed_stick') :
        cts.contactsMobileButton.parentNode.classList.add('collapsed_stick');
};

document.addEventListener('click', function (event) {
    let isClickInsideContacts = cts.contactDetailsMobile.contains(event.target);
    if (!isClickInsideContacts) {
        if (cts.contactsMobileButton.parentNode.classList.contains('collapsed_stick')) {
            cts.contactsMobileButton.parentNode.classList.remove('collapsed_stick');
        }
    }
});

initialzeBot();

async function start(){
    await sleep(1000);
    cts.header.classList.remove('fadeout');
    fadein(cts.introMsg)
    await autotypeIntro();

}
async function startTalking() {
    await sleep(1000);
    loopFooter();
    cts.myapp.removeChild(document.querySelector('.intro-msg'));
    await sleep(1000);
    fadein(cts.introVideo);
    var isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    if(!isSafari){
        cts.introVideo.onended = async () => {
            if(!success){
                await afterIntro();
            }
            try {
                cts.myapp.removeChild(cts.introVideo);
            }
            catch (e) {
                // console.log(e)
            }
        }
        cts.introVideo.play().then(()=>{cts.introVideo.pause();cts.introVideo.play();}).catch(
            async (er) => {
                if(!success){
                    await afterIntro();
                }
                try {
                    cts.myapp.removeChild(cts.introVideo);
                }
                catch (e) {
                    // console.log(e, er)
                }
            });
    }
    else {
        await  afterIntro();
        cts.introVideo.style.display = 'none';
    }


}
async  function afterIntro(){
    success = true;
    fadeout(cts.introVideo);
    cts.triangles.map((triangle) => triangle.classList.remove('fadeout'));
    await sleep(100);
    fadein(cts.contactDetailsMobile);
    fadein(cts.laptop);
    document.body.style.backgroundColor = 'rgb(255,253,255)';
    fadein(cts.mainVideo);
    cts.laptop.classList.remove('downed');
    await sleep(1000);
    fadein(cts.robot);
    await sleep(1000);
    fadein(cts.footerCloseButton)
    cts.mainVideo.loop = true;
    const elementsArray = [cts.p1, cts.p2, cts.p3, cts.s1, cts.p4, cts.s2, cts.p5];
    if (wantTypeEffect) {
        for (let j = 0; j < cts.tutotialText.length; j++) {
            for (let i = 0; i < cts.tutotialText[j].length; i++) {
                elementsArray[j].innerHTML += cts.tutotialText[j].charAt(i);
                await sleep(30);
            }
        }
    }
    await sleep(4000);
    fadeout(cts.footer);
    await  sleep(1000);
    if (cts.isMobile) cts.footer.remove();
}

setTimeout(() => {
    setTimeout(() => start().catch(console.error), 500);
}, 100);

function startChat() {
    started = true;
    fadeout(cts.laptopText);
    var isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

    if(!isSafari){
        cts.mainVideo.play()
            .then(() => {
                cts.mainVideo.play();
            })
            .catch((error) => {
                alert('error', error.message);
            });
    }

    botInputFrame.classList.remove('glowing');

    let laptopWrap = document.querySelector('.laptop');
    if (laptopWrap && cts.laptopText) {
        setTimeout(() => laptopWrap.removeChild(cts.laptopText), 1000)
    }
}

let botInput, botInputFrame;

function wantNewToken() {
    if ((Date.now() - Number(localStorage.getItem('tokenReciveDate'))) / 1000 > 1500 || !localStorage.getItem('token')) {
        return true;
    }
    else {
        return false;
    }

}

function fetchToken() {
    var url = 'https://directline.botframework.com/v3/directline/tokens/generate';
    var xhttp = new XMLHttpRequest();
    xhttp.open("POST", url, true);

    xhttp.setRequestHeader("Authorization", "Bearer XY2WVdOhKd8.cwA.03U.sEpRzymyBJbwcy33oD-5YZ8usAFbKFxNohccHIZ2WfE");
    xhttp.onreadystatechange = async function () {
        if (this.readyState === 4 && this.status === 200) {
            let response = JSON.parse(this.response);
            localStorage.setItem('conversationId', response.conversationId);
            localStorage.setItem('token', response.token);
            localStorage.setItem('expires_in', response.expires_in);
            localStorage.setItem('tokenReciveDate', Date.now());
            await fetchIp();
            botCreator(response.token);
        }

    };
    xhttp.send();
}

async function autotypeIntro(){
    sleep(1000);
    for (let j = 0; j < cts.introAutotypeMessages.length; j++) {
        for (let i = 0; i < cts.introAutotypeMessages[j].length; i++) {
            cts.introParagraphs[j].innerHTML += cts.introAutotypeMessages[j].charAt(i);
            await sleep(30);
        }
    }
    fadein(cts.startBtn);
}


function sendToken() {
    // console.log('send token');
    const url = 'https://teo-backend.herokuapp.com/chattoken';
    const xhttp = new XMLHttpRequest();
    xhttp.responseType = 'json';

    xhttp.open("POST", url, true);

    xhttp.onreadystatechange = function () {
        if (this.readyState === 4 && this.status === 200) {
            // console.log(this.response, Date.now(), 'sukces wyslania tokenu');
            sendIp();
        }
    };
    xhttp.setRequestHeader("Content-Type", "application/json");

    const toSend = {
        token: localStorage.getItem('token'),
        conversationId: localStorage.getItem('conversationId')
    };
    // console.log(JSON.stringify(toSend), 'to wysylam');
    xhttp.send(JSON.stringify(toSend));
}
async function botCreator(token) {
    const directLine = new BotChat.DirectLine({token});

    await BotChat.App({
        botConnection: directLine,
        user: {id: 'userId', name: 'You'},
        bot: {id: 'botId', name: 'TEO'},
        resize: 'window',
        locale: 'en'
    }, document.getElementById("bot_target"));

    directLine.activity$
        .subscribe(
            activity => {
                wantTypeEffect = false;
                // console.log(activity);
                onMessage();
                try {
                    // console.log(activity.attachments[0].content.body[0].items[0].columns[0].items[0]);
                    if(activity.attachments[0].content.body[0].items[0].columns[0].items[0].text.includes('Hi')){
                        // console.log("JEST TO CO SZUKALEM");
                        sendToken();
                    }
                }
                catch (e) {

                }
            }
        );

    botInput = document.querySelector('.wc-shellinput');
    botInput.onfocus = function () {
        const element = document.querySelector('.wc-message-groups');
        element.scrollTop = element.scrollHeight;
        if(cts.isMobileDevice.any()){
            fadeout(cts.laptopText);
        }

        window.onscroll = () => {
            null;
        };
    };

    botInputFrame = document.querySelector('.wc-console');
    botInput.onkeydown = (e) => {
        if (e.key === 'Enter' || e.key === 'enter') {
            if (!started) startChat();
        }
    };
    document.body.onresize = function () {
        const element = document.querySelector('.wc-message-groups');
        if (element) element.scrollTop = element.scrollHeight;
    };

    // const isTouch = (('ontouchstart' in window) || (navigator.msMaxTouchPoints > 0));
    // const isLandscape = screen.height < screen.width;
    // if(isTouch && isLandscape) {
    //     botInput.onfocus = () => {
    //         cts.laptopText.style.display = 'none';
    //         botInputFrame.classList.add('imba');
    //     }
    //     botInput.onblur = () => {
    //         botInputFrame.classList.remove('imba');
    //     }
    // }
    // window.addEventListener("orientationchange", function() {
    //     if(isTouch && isLandscape) {
    //         botInput.onfocus = () => {
    //             cts.laptopText.style.display = 'none';
    //             botInputFrame.classList.add('imba');
    //         }
    //         botInput.onblur = () => {
    //             botInputFrame.classList.remove('imba');
    //         }
    //     }
    // });

    const botSendBtn = document.querySelector('.wc-send');
    botSendBtn.onclick = (e) => {
        showTutorial = false;
        if (!started) startChat();
    };

    botInput.blur();
    botInput.setAttribute('placeholder', "Enter your message here");
    botInputFrame.classList.add('glowing');

    setTimeout(()=> botInput.blur(), 200);
}

function initialzeBot() {
    if (true) {
        fetchToken();
    }
    else {
        botCreator(localStorage.getItem('token'));
    }
}

function onMessage() {
    const element = document.querySelector('.wc-message-groups');
    element.scrollTop = element.scrollHeight;
}

async function loopFooter(){
    cts.footer.style.zIndex = '1111111111111';
    fadein(cts.footer);
    while(footerInterval){
        for(let msg of cts.footerMessages){
            cts.footerText.innerHTML = msg;
            fadein(cts.footerText);
            await sleep(3 * cts.msgDuration);
            fadeout(cts.footerText);
            await sleep(1000);
            if(!footerInterval)break;
        }
    }
}


async function fetchIp() {
    var url = 'https://api.ipify.org?format=json';
    var xhttp = new XMLHttpRequest();
    xhttp.open("GET", url, true);
    xhttp.onreadystatechange = function () {
        if (this.readyState === 4 && this.status === 200) {
            let response = JSON.parse(this.response);
            // console.log(response);
            localStorage.setItem('userIp', response.ip);
        }
    };
    xhttp.send();
}

function sendIp() {
    var url = 'https://teo-backend.herokuapp.com/addip';
    // var url = 'http://localhost:4000/addip';
    var xhttp = new XMLHttpRequest();
    xhttp.open("POST", url, true);
    xhttp.responseType = 'json';
    xhttp.setRequestHeader("Content-Type", "application/json");
    xhttp.onreadystatechange = function () {
        if (this.readyState === 4 && this.status === 200) {
            // console.log(this.response, "sending ip success", Date.now());
        }
    };
    var data = {ip: localStorage.getItem('userIp'), conversationId: localStorage.getItem('conversationId')}
     console.log(data);
    xhttp.send(JSON.stringify(data));
}


